<template>
  <div class="balance-wrap">
    <div class="header-balance-text">
      <p>Your Balance</p>
      <a
        class="help-text"
        target="_blank"
        href="https://medium.com/theos-fi/theos-uniswap-liquidity-mining-campaign-2ad6b4a8bbe1"
        >Do you need help?</a
      >
    </div>
    <div class="info-data-balance">
      <div class="info-text">
        <p>Total</p>
        <div class="info-text-hover">
          <img class="info-icon" src="@/Common/Icons/InfoRoyaltiesIcon.png" />
          <div class="message-hover">Your total Liquidity token balance</div>
        </div>
      </div>
      <p class="total-balance">
        {{ toDecimal(total) }}
        <span class="uni-text">UNI-V2 {{ rewardsToken }}/{{ quoteToken }}</span>
      </p>
      <div class="add-liquidity">
        <img class="" src="@/Common/Icons/AddLiquidity.png" />
        <a
          href="https://app.uniswap.org/#/add/v2/0x9e10f61749c4952c320412a6b26901605ff6da1d/ETH"
          target="_blank"
          class="liquidity-text"
          >Add Liquidity</a
        >
      </div>
    </div>
    <div class="info-data-balance">
      <div class="info-text">
        <p>Available</p>
        <div class="info-text-hover">
          <img class="info-icon" src="@/Common/Icons/InfoRoyaltiesIcon.png" />
          <div class="message-hover available">
            Your total available-for-staking liquidity <br />
            tokens balance
          </div>
        </div>
      </div>
      <p class="available-balance">
        {{ toDecimal(walletBalance) }}
        <span class="uni-text">UNI-V2 {{ rewardsToken }}/{{ quoteToken }}</span>
      </p>
    </div>
    <div class="info-data-balance">
      <div class="info-text">
        <p>Staked</p>
        <div class="info-text-hover">
          <img class="info-icon" src="@/Common/Icons/InfoRoyaltiesIcon.png" />
          <div class="message-hover">Your staked liquidity token balance</div>
        </div>
      </div>
      <p class="staked-balance">
        {{ toDecimal(staked) }}
        <span class="uni-text">UNI-V2 {{ rewardsToken }}/{{ quoteToken }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import useLiquidityMining from "@/composables/useLiquidityMining";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const {
      total,
      staked,
      toDecimal,
      rewardsToken,
      quoteToken,
      walletBalance,
    } = useLiquidityMining(store);
    return {
      total,
      staked,
      toDecimal,
      rewardsToken,
      quoteToken,
      walletBalance,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/userBalance.scss"
></style>
