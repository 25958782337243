<template>
  <div class="liquidity-mining-wrap">
    <p class="headline-liquidity-all">
      <span class="headline-liquidity">THEOS</span> LIQUIDITY MINING
    </p>
    <div class="max-width-wrapper">
      <div class="stake-balance-wrap">
        <stake />
        <user-balance />
      </div>
      <liquidity-mining-overview />
      <theos-info />
      <liquidity-modals />
    </div>
  </div>
</template>
<script>
import Stake from "@/components/LiquidityMining/Stake";
import UserBalance from "@/components/LiquidityMining/UserBalance";
import LiquidityMiningOverview from "@/components/LiquidityMining/LiquidityMiningOverview.vue";
import TheosInfo from "@/components/LiquidityMining/TheosInfo.vue";
import LiquidityModals from "@/components/LiquidityMining/LiquidityModals/LiquidityModals.vue";
export default {
  components: {
    Stake,
    UserBalance,
    LiquidityMiningOverview,
    TheosInfo,
    LiquidityModals,
  },
  setup() {
    return {};
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/liquidityMining.scss"
></style>
