import { computed, reactive } from "vue";
import { useRouter } from "vue-router";

const user = reactive({
  displayName: "",
  description: "",
  email: "",
  websiteUrl: "",
  twitterUsername: "",
});

const useRegistrationModal = (myStore) => {
  const router = useRouter();

  const registrationFlag = computed(() =>
    myStore.getters["registration/getByKey"]("registrationFlag")
  );

  const uploadImage = (img) =>
    myStore.dispatch("registration/setProfileImageUrl", img);

  const toggleRegistrationFlag = () =>
    myStore.dispatch("registration/toggleRegistrationFlag");

  const currentStep = computed(() =>
    myStore.getters["registration/getByKey"]("currentStep")
  );

  const currentStepsArray = computed(() =>
    myStore.getters["registration/getByKey"]("currentStepsArray")
  );

  const profileImageUrl = computed(() =>
    myStore.getters["registration/getUserByKey"]("profileImageUrl")
  );

  const commitToStore = (data) => {
    myStore.dispatch("registration/commitByKey", data);
  };

  const resetState = () => myStore.dispatch("registration/resetState");

  const updateUser = async () => {
    const userData = myStore.getters["registration/getUser"];
    //TODO: this is hardcoded, we should send url of uploaded img
    userData.profileImageUrl =
      "https://i.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0";
    return myStore.dispatch("registration/updateUser", userData);
  };

  //   const setUser = () => myStore.dispatch("userProfile/commitByKey", user);

  const finishRegistration = async () => {
    commitToStore(user);
    toggleRegistrationFlag();
    await updateUser();
    // setUser();
    await myStore.dispatch("userProfile/fetchMe");
    router.push("/profile");
    resetState();
  };
  const incrementStep = () => {
    myStore.dispatch("registration/incrementStep");
  };
  const decrementStep = () => myStore.dispatch("registration/decrementStep");
  const dismiss = () => {
    router.push("/");
    toggleRegistrationFlag();
    resetState();
  };
  return {
    registrationFlag,
    toggleRegistrationFlag,
    currentStep,
    dismiss,
    incrementStep,
    decrementStep,
    profileImageUrl,
    uploadImage,
    finishRegistration,
    commitToStore,
    currentStepsArray,
    user,
  };
};

export default useRegistrationModal;
