import { ethereumService } from "@/main";
import exchange from "../../services/exchange";
import nftStorage from "../../services/ntf-storage";

const initialState = () => ({
  nftMetadata: {},
  nftData: {},
  errors: [],
  coinPrices: [],
  coinPriceTime: "",
  address: "",
  balance: {},
  network: {},
});
const state = initialState();

const getters = {
  getNftMetadata(state) {
    return state.nftMetadata;
  },
  getNftData(state) {
    return state.nftData;
  },
  getErrors(state) {
    return state.errors;
  },
  getAddress(state) {
    return state.address;
  },
  getBalance(state) {
    return state.balance;
  },
  getBalanceByCurrency: (state) => (currency) => {
    return state.balance[currency];
  },
  getCoinPrice: (state) => (coin, currency) => {
    return state.coinPrices
      .filter(
        (coinPricePair) =>
          coinPricePair.coin === coin && coinPricePair.currency === currency
      )
      .pop();
  },
  getCoinPriceTime(state) {
    return state.coinPriceTime;
  },
  getNetwork(state) {
    return state.network;
  },
};
const mutations = {
  setNftMetadata(state, metadata) {
    state.nftMetadata = metadata;
  },
  setNftData(state, data) {
    state.nftData = data;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setAddress(state, address) {
    state.address = address;
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setCoinPrice(state, coinPricePair) {
    const exists = state.coinPrices.find(
      (pair) =>
        coinPricePair.coin === pair.coin &&
        coinPricePair.currency === pair.currency
    );
    if (exists) {
      state.coinPrices.map((pair) => {
        if (
          coinPricePair.coin === pair.coin &&
          coinPricePair.currency === pair.currency
        ) {
          pair.value = coinPricePair.value;
        }
        return pair;
      });
    } else {
      state.coinPrices.push(coinPricePair);
    }
  },
  setCoinPriceTime(state, coinPriceTime) {
    state.coinPriceTime = coinPriceTime;
  },
  setNetwork(state, network) {
    state.network = network;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async storeNft({ commit }, nftData) {
    try {
      const result = await nftStorage.store(
        nftData.file,
        nftData.name,
        nftData.description
      );
      commit("setNftMetadata", result);
      return result;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async mintNft({ commit }, nftMetadata) {
    try {
      const result = await ethereumService.nftMint(nftMetadata);
      commit("setNftData", result);
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async fetchAddress({ commit }) {
    try {
      const address = await ethereumService.fetchAddress();
      commit("setAddress", address);
      return address;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchBalance({ commit }, balanceAddress) {
    try {
      const balance = await ethereumService.fetchBalance(balanceAddress);
      commit("setBalance", balance);
      return balance;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async signNonce({ commit }, nonce) {
    try {
      return await ethereumService.signNonce(nonce);
    } catch (error) {
      if ("code" in error && error.code === 4001) {
        return "rejected";
      }
      commit("pushError", error);
    }
  },
  async fetchCoinPrice(
    { commit, getters },
    { coin = "ethereum", currency = "usd" }
  ) {
    try {
      if (
        new Date() - new Date(getters.getCoinPriceTime) > 60 * 1000 ||
        getters.getCoinPriceTime === ""
      ) {
        const response = await exchange.fetchPriceInFiat(coin, currency);
        commit("setCoinPrice", {
          coin,
          currency,
          value: response.data[coin][currency],
        });
        commit("setCoinPriceTime", new Date());
        return response.data[coin][currency];
      }
      return getters.getCoinPrice(coin, currency);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchNetwork({ commit }) {
    try {
      const result = await ethereumService.getNetwork();
      commit("setNetwork", result);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
