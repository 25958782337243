import store from "./store";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import VueSocialSharing from "vue-social-sharing";
import EthereumService from "./services/ethereum";
import { apolloClient } from "./plugins/vue-apollo";
import { provideApolloClient } from "@vue/apollo-composable";

import "@/Common/Styles/base.scss";

import mitt from "mitt";
export const eventBus = mitt();

const app = createApp(App).use(store).use(router).use(VueSocialSharing);

const CHAIN_ID = 1;
export const ethereumService = new EthereumService(CHAIN_ID);

provideApolloClient(apolloClient);
app.provide("eventBus", eventBus);
app.provide("ethereumService", ethereumService);
// app.provide(DefaultApolloClient, apolloClient);
app.mount("#app");
