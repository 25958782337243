import api from "../../services/api";

const initialState = () => ({
  user: {
    id: null,
    publicAddress: "",
    nonce: "",
    email: "",
  },
  session: "",
  hasPendingTransaction: false,
  pendingTransactions: [],
  users: [],
  errors: [],
});
const state = initialState();

const getters = {
  getUser(state) {
    return state.user;
  },
  getUsers(state) {
    return state.users;
  },
  getSession(state) {
    return state.session;
  },
  getHasPendingTransaction(state) {
    return state.hasPendingTransaction;
  },
  getErrors(state) {
    return state.errors;
  },
  getPendingTransactions(state) {
    return state.pendingTransactions;
  },
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setHasPendingTransaction(state, status) {
    state.hasPendingTransaction = status;
  },
  setSession(state, session) {
    state.session = session;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
  addPendingTransaction(state, tx) {
    state.pendingTransactions = [...state.pendingTransactions, tx];
  },
  removePendingTransaction(state, tx) {
    state.pendingTransactions.filter(
      (transaction) => transaction.hash !== tx.hash
    );
  },
};

const actions = {
  async fetchUser({ commit }, id) {
    try {
      const result = await api.fetchUser(id);
      commit("setUser", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchUsers({ commit }) {
    try {
      const result = await api.fetchUsers();
      commit("setUsers", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async updateUser({ commit }, { userData, id }) {
    try {
      const result = await api.updateUser(userData, id);
      commit("setUser", result.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  updateSession({ commit }, session) {
    commit("setSession", session);
  },
  updateHasPendingTransaction({ commit }, status) {
    commit("setHasPendingTransaction", status);
  },
  async resetState({ commit }) {
    commit("resetState");
  },
  addPendingTx({ commit }, tx) {
    commit("addPendingTransaction", tx);
  },
  removePendingTx({ commit }, tx) {
    commit("removePendingTransaction", tx);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
