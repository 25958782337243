import * as Yup from "yup";

const URL =
  "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";

export const registrationDetailsSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  websiteUrl: Yup.string().nullable().notRequired().matches(URL, {
    message: "Please enter a valid url",
    excludeEmptyString: true,
  }),
});
