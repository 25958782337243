<template>
  <img
    class="app-modal-avatar"
    :src="imgSrc"
    alt="Avatar"
    height="100"
    width="100"
  />
</template>

<script>
import { computed } from "vue";

export default {
  name: "RegistrationAvatar",
  props: {
    uploadedImage: {
      type: String,
    },
  },
  setup(props) {
    const imgSrc = computed(() =>
      props.uploadedImage
        ? props.uploadedImage
        : require(`@/Common/Icons/Avatar.png`)
    );
    return {
      imgSrc,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/registrationSteps.scss"></style>
