<template>
  <teleport to="body">
    <app-registration>
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img
            src="@/Common/Icons/LOGO_GIF.gif"
            alt="Logo"
            class="logo-gif"
            style="margin: 50px 0"
          />
          <div class="text-wrap">
            <p
              class="theos-info-stake"
              style="margin: 0 auto; text-align: center"
            >
              Support for smaller display sizes is coming soon!
            </p>
          </div>
        </div>
      </template>
    </app-registration>
  </teleport>
</template>
<script>
import AppRegistration from "@/elements/AppRegistration.vue";
export default {
  components: { AppRegistration },
};
</script>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/liquidityModals.scss"
></style>
