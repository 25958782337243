import useAlert from "./useAlert";
import useRegistrationModal from "@/composables/useRegistrationModal";
import { computed } from "vue";
import { useRouter } from "vue-router";
import apolloService from "../services/apolloService";

const useAuth = (store, ethereumService, router = useRouter()) => {
  const { showAlert } = useAlert();
  const disconnect = async (isAccountChange = false) => {
    await store.dispatch("contracts/resetState");
    await store.dispatch("users/resetState");
    await store.dispatch("liquidity/resetState");
    if (!isAccountChange) {
      await ethereumService.disconnectProviderWithoutReloading();
    }
    return await router.push("/");
  };
  const handleAccountChange = async (account) => {
    showAlert(
      `Account changed: ${account}`,
      `Please, sign the transaction on Metamask to continue.`,
      "Success"
    );
    await disconnect(true);
    await loginOrSignUp(true);
  };
  const handleChainChange = async (chain) => {
    if (chain === "Selected network is not supported.") {
      showAlert("Network not supported", chain, "Error");
      return await disconnect();
    }
    showAlert("Network changed", chain, "Success");
    let publicAddress = await store.dispatch("contracts/fetchAddress");
    await store.dispatch("contracts/fetchNetwork");
    await store.dispatch("contracts/fetchBalance", publicAddress);
  };

  const loginOrSignUp = async (isAccountChange = false) => {
    await ethereumService.connectToProvider();
    const network = await ethereumService.isValidNetwork();
    if (!network.isValid) {
      showAlert(
        "Network not supported",
        "Selected network is not supported.",
        "Error"
      );
      return await disconnect();
    }
    if (!isAccountChange) {
      await ethereumService.onAccountChanged(handleAccountChange);
    }
    await ethereumService.onChainChanged(handleChainChange);
    await store.dispatch("users/updateHasPendingTransaction", true);
    await store.dispatch("contracts/fetchNetwork");
    let publicAddress = await store.dispatch("contracts/fetchAddress");
    await store.dispatch("contracts/fetchBalance", publicAddress);
    const authMessage = await apolloService.getAuthenticationMessage(
      publicAddress
    );
    if (!authMessage) {
      console.log("GQL ERROR");
    }
    const signature = await store.dispatch("contracts/signNonce", authMessage);
    if (signature === "rejected") {
      showAlert(
        "MetaMask error",
        "Please, sign the message to access the advanced features of the application.",
        "Error"
      );
      await store.dispatch("users/updateHasPendingTransaction", false);
      return disconnect();
    }
    const session = await apolloService.getAuth(signature, authMessage);
    await store.dispatch("users/updateHasPendingTransaction", false);
    await store.dispatch("users/updateSession", session);
    const result = await ethereumService.isProviderConnected();
    await store.dispatch("liquidity/commitByKey", { isConnected: result });
  };

  const openRegistrationModal = async () => {
    const address = computed(() => store.getters["contracts/getAddress"]);
    const { toggleRegistrationFlag } = useRegistrationModal(store);
    if (address.value) {
      const registered = await isUserRegistered();
      if (!registered) {
        toggleRegistrationFlag();
      }
    }
  };

  const isUserRegistered = async () => {
    await store.dispatch("auth/fetchMe");
    const me = store.getters["auth/getRegisteredUser"];
    return Boolean(me.displayName);
  };

  return {
    loginOrSignUp,
    handleChainChange,
    handleAccountChange,
    openRegistrationModal,
  };
};

export default useAuth;
