import api from "@/services/api";

const initialState = () => ({
  user: {
    avatar:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    displayName: "",
    description: "",
    address: "",
    websiteUrl: "",
    twitterUsername: "",
    email: "",
  },
  currentPage: 1,
  list: { total: 0 },
  nftDataList: [],
  errors: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
  getUser: (state) => state.user,
};

const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearNftList(state) {
    state.list = { total: 0 };
  },
  setNftDataList(state, list) {
    state.nftDataList = state.nftDataList.concat(list);
  },
  clearNftDataList(state) {
    state.nftDataList = [];
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};
const actions = {
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  async fetchNftList({ commit }, { params }) {
    try {
      const response = await api.fetchNftList(params);
      commit("commitByKey", { list: response.data });
      commit("setNftDataList", response.data.data);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const response = await api.fetchMe();
      commit("commitByKey", { user: response.data.data });
      return response;
    } catch (error) {
      commit("pushError", error);
    }
  },
  resetState({ commit }) {
    commit("resetState");
  },
  clearNftList({ commit }) {
    commit("clearNftList");
  },
  clearNftDataList({ commit }) {
    commit("clearNftDataList");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
