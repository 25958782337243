import gql from "graphql-tag";

export const gqlQueries = {
  TestQuery: gql`
    query {
      geLiquidityMiningList {
        list {
          address
          name
        }
      }
    }
  `,
  GetTransactionDocument: gql`
    query GetTransaction($session: Session!, $txHash: Hash!) {
      getTransaction(session: $session, txHash: $txHash) {
        txHash
        status
        events {
          contract
          data
          name
        }
      }
    }
  `,
  GetLiquidityMiningPanelDocument: gql`
    query GetLiquidityMiningPanel($session: Session, $address: Address!) {
      getLiquidityMiningPanel(session: $session, address: $address) {
        rewardsToken {
          name
        }
        stakingToken {
          name
        }
        quoteToken {
          name
          symbol
        }
        mainTokenPriceQuote
        v2 {
          totalLockedTokens
          totalLiquidityUSD
        }
        stakingTokenAllowance
        totalBalance
        walletBalance
        stakingBalance
        mainTokenPriceQuote
        mainTokenPriceUSD
        liquidityMinig {
          apy
          total
          claimed
          availableForClaiming
        }
      }
    }
  `,
  UnstakeLiquidityMiningDocument: gql`
    mutation UnstakeLiquidityMining(
      $session: Session!
      $input: LiquidityMiningUnstakeInput!
    ) {
      createTxLiquidityMiningUnstake(session: $session, input: $input) {
        from
        nonce
        gasPrice
        gas
        to
        value
        input
        chainId
      }
    }
  `,
  StakeLiquidityMiningDocument: gql`
    mutation StakeLiquidityMining(
      $session: Session!
      $input: LiquidityMiningStakeInput!
    ) {
      createTxLiquidityMiningStake(session: $session, input: $input) {
        from
        nonce
        gasPrice
        gas
        to
        value
        input
        chainId
      }
    }
  `,
  GetAuthMessageDocument: gql`
    mutation GetAuthMessage($address: String!) {
      getAuthMessage(address: $address)
    }
  `,
  ClaimLiquidityMiningDocument: gql`
    mutation ClaimLiquidityMining($session: Session!, $address: Address!) {
      createTxLiquidityMiningGetReward(session: $session, address: $address) {
        chainId
        from
        gas
        gasPrice
        input
        nonce
        to
        value
      }
    }
  `,
  AuthDocument: gql`
    mutation Auth($input: AuthInput!) {
      auth(input: $input) {
        session
        address
      }
    }
  `,
  ApproveLiquidityMiningDocument: gql`
    mutation ApproveLiquidityMining(
      $session: Session!
      $input: LiquidityMiningApproveInput!
    ) {
      createTxLiquidityMiningApprove(session: $session, input: $input) {
        from
        nonce
        gasPrice
        gas
        to
        value
        input
        chainId
      }
    }
  `,
};
