<template>
  <div class="stepper-wrapper">
    <div
      class="stepper-item"
      v-for="(step, index) in steps"
      :key="index"
      :class="{
        completed: isStepCompleted(index),
      }"
    >
      <div
        class="step-counter"
        :class="{
          active: isStepActive(index),
          completed: isStepCompleted(index),
        }"
      >
        {{ showStepNumber(index) }}
      </div>
      <div
        class="step-name"
        :class="{
          active: isStepActive(index),
          completed: isStepCompleted(index),
        }"
      >
        <div class="stepper-text" v-if="step.title === ''"></div>
        {{ step.title }}
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "Stepper",
  props: {
    steps: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const currentStep = computed(() =>
      store.getters["minting/getByKey"]("currentStep")
    );
    const uploadedFile = computed(() =>
      store.getters["minting/getByKey"]("selectedFile")
    );
    const showStepNumber = (index) =>
      index <= 0 ? index + 1 : uploadedFile.value.type ? index + 1 : "";

    const isStepCompleted = (index) => index + 1 < currentStep.value;

    const isStepActive = (index) => index + 1 == currentStep.value;

    return {
      currentStep,
      isStepActive,
      showStepNumber,
      isStepCompleted,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/stepper.scss"></style>
