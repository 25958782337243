<template>
  <app-input
    type="number"
    v-model="amount"
    :value="amount"
    inputClass="app-checkbox-input"
    :placeholder="'0'"
    :placeholderRight="'UNI-V2 ' + rewardsToken + '/' + quoteToken"
    class="stake-amount"
  >
  </app-input>
  <p class="stake-balance">
    Balance: {{ toDecimal(staked) }} UNI-V2 {{ rewardsToken }}/{{ quoteToken }}
    <span class="stake-balance-max" @click="setMaxUnstake()">Use max.</span>
  </p>
  <app-button
    buttonClass="app-modal-button-inverse"
    :disabled="!isValidInput || !isConnected"
    :text="isValidInput ? 'Unstake' : 'Enter a valid amount'"
    @click="unstake()"
  />
</template>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/stake.scss"
></style>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useLiquidityMining from "@/composables/useLiquidityMining";
import { BigNumber } from "bignumber.js";

export default {
  components: {
    AppButton,
    AppInput,
  },
  setup() {
    const store = useStore();
    const amount = ref(null);
    const {
      toBigNumber,
      toDecimal,
      staked,
      handleUnstaking,
      rewardsToken,
      quoteToken,
    } = useLiquidityMining(store, ethereumService);

    const setMaxUnstake = () => (amount.value = toDecimal(staked.value));

    const isValidInput = computed(() =>
      Boolean(
        toBigNumber(amount.value || 0).isGreaterThan(toBigNumber(0)) &&
          toBigNumber(amount.value || 0).isLessThanOrEqualTo(
            toBigNumber(staked.value)
          )
      )
    );

    const isConnected = computed(() =>
      store.getters["liquidity/getByKey"]("isConnected")
    );

    const unstake = () => {
      const amount_temp = new BigNumber(amount.value);
      const finalAmount = amount_temp
        .multipliedBy(1_000_000_000_000_000_000)
        .toFixed();
      handleUnstaking(finalAmount);
    };

    return {
      staked,
      amount,
      unstake,
      isConnected,
      isValidInput,
      setMaxUnstake,
      handleUnstaking,
      rewardsToken,
      quoteToken,
      toDecimal,
    };
  },
};
</script>
