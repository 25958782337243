import { ethers } from "ethers";
import Web3Modal from "web3modal";
import ABI from "../contracts/NftMint.json";
// import THEOS_MAINNET from "../contracts/TheosMainnet.json";
// import THEOS_ROPSTEN from "../contracts/TheosRopsten.json";
import ADDRESSES from "../contracts/addresses.json";

class EthereumService {
  constructor(chainId = 1) {
    this.chainId = chainId;
    this.walletLink = {};
    this.networkProvider = {};
    this.providerOptions = {};
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: this.providerOptions,
      theme: "dark",
    });
  }

  parseChainId = (id) => {
    const chains = {
      1: "mainnet",
    };
    return chains[id] || "Selected network is not supported.";
  };

  async connectToProvider() {
    this.networkProvider = await this.web3Modal.connect();
    const network = await this.isValidNetwork();
    if (!network.isValid) {
      try {
        await this.networkProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async checkCachedProvider() {
    if (this.web3Modal.cachedProvider) {
      this.networkProvider = await this.web3Modal.connect();
    }
  }

  async onChainChanged(handleChainChange) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on("chainChanged", async (info) => {
        await handleChainChange(this.parseChainId(parseInt(info, 16)));
      });
    }
  }

  async onAccountChanged(handleAccountChange) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      this.networkProvider.on(
        "accountsChanged",
        async (accounts) => await handleAccountChange(accounts[0])
      );
    }
  }

  async isProviderConnected() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts.length > 0;
    }
    return false;
  }

  async getNetwork() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      return await provider.getNetwork();
    }
    return {};
  }

  async isValidNetwork() {
    const networkName = (await this.getNetwork()).name;
    return {
      name: networkName,
      isValid: ["homestead"].includes(networkName),
    };
  }

  async disconnectProvider() {
    this.web3Modal.clearCachedProvider();
    if (this.networkProvider._relay?.appName === "Theos") {
      this.walletLink.disconnect();
    }
    location.reload();
  }

  async disconnectProviderWithoutReloading() {
    this.web3Modal.clearCachedProvider();
    if (this.networkProvider._relay?.appName === "Theos") {
      this.walletLink.disconnect();
    }
  }

  async nftMint(metadata) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        ADDRESSES["NftMintContract"],
        ABI["abi"],
        signer
      );
      return contract.mintToken(signer.getAddress(), metadata);
    }
    throw new Error("No network provider found!");
  }

  async fetchAddress() {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const accounts = await provider.listAccounts();
      return accounts[0];
    }
    throw new Error("No network provider found!");
  }

  async fetchBalance(address) {
    await this.checkCachedProvider();
    const balances = {};
    // const valid = await this.isValidNetwork();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const balance = await provider.getBalance(address);
      balances["ETH"] = ethers.utils.formatEther(balance);
      // if (valid) {
      //   const network = this.parseChainId(
      //     (await provider.getNetwork()).chainId
      //   );
      //   const abi =
      //     network === "mainnet" ? THEOS_MAINNET["abi"] : THEOS_ROPSTEN["abi"];
      //   const contract = new ethers.Contract(
      //     ADDRESSES["THEOS"][network],
      //     abi,
      //     provider.getSigner()
      //   );
      //   const balanceTHEOS = await contract.balanceOf(address);
      //   balances["THEOS"] = ethers.utils.formatEther(balanceTHEOS);
      // }
      return balances;
    }
    return [];
  }

  async signNonce(nonce) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      return await signer.signMessage(nonce);
    }
    throw new Error("No network provider found!");
  }

  async sendTransaction(transaction) {
    await this.checkCachedProvider();
    if (Object.keys(this.networkProvider).length > 0) {
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      transaction.gasLimit = transaction.gas;
      transaction.data = transaction.input;
      delete transaction.input;
      delete transaction.gas;
      delete transaction.__typename;
      delete transaction.gasPrice;
      return await signer.sendTransaction(transaction);
      // return await result.wait();
    }
    throw new Error("No network provider found!");
  }

  async addTheosToMetamask() {
    return await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: "0x9e10f61749c4952c320412a6b26901605ff6da1d",
          symbol: "THEOS",
          decimals: 18,
          image: "https://theos.fi/assets/img/logo_theos.svg",
        },
      },
    });
  }
}

export default EthereumService;
