import {
  mintNftFileScheme,
  mintNftCoverImageScheme,
  mintNftDescriptionSchema,
  mintNftDetailsScheme,
} from "@/validationSchemas";

const stepsDefault = [
  {
    component: "MintNftFile",
    title: "NFT File",
    scheme: mintNftFileScheme,
  },
  {
    title: "",
  },
  {
    title: "",
  },
  {
    title: "",
  },
];
const stepsForImages = [
  {
    component: "MintNftFile",
    title: "NFT File",
    scheme: mintNftFileScheme,
  },
  {
    component: "MintDescription",
    title: "Description",
    scheme: mintNftDescriptionSchema,
  },
  {
    component: "MintDetails",
    title: "Details",
    scheme: mintNftDetailsScheme,
  },
];
const stepsForOtherMimeTypes = [
  {
    component: "MintNftFile",
    title: "NFT File",
    scheme: mintNftFileScheme,
  },
  {
    component: "MintCoverImage",
    title: "Cover Image",
    scheme: mintNftCoverImageScheme,
  },
  {
    component: "MintDescription",
    title: "Description",
    scheme: mintNftDescriptionSchema,
  },
  {
    component: "MintDetails",
    title: "Details",
    scheme: mintNftDetailsScheme,
  },
];
const initialState = {
  name: "",
  tags: "",
  errors: [],
  dutation: 0,
  royalties: null,
  isMinting: false,
  currentStep: 1,
  description: "",
  selectedFile: {},
  coverImageFile: {},
  backgroundUrl: "",
  fileTypeCover: "",
  mintingMessage: "",
  alternativeText: "",
  isMintingFinished: false,
  requiresCoverImage: false,
  shouldShowNftPreview: true,
  isCoverAutogenerated: false,
  shouldShowMintNftStepper: true,
  currentStepsArray: stepsDefault,
};
const state = initialState;
const getters = {
  getByKey: (state) => (key) => state[key],
  isFileSelected: (state) => Boolean(state.selectedFile.type),
  getSelectedFileMimeType: (state) => state.selectedFile?.type?.split("/")[1],
  getSelectedFileType: (state) => state.selectedFile?.type?.split("/")[0],
  getCurrentStepsArray: (state) => state.currentStepsArray,
};
const mutations = {
  changeCurrentStepsArray(state, fileType) {
    state.currentStepsArray =
      fileType === "image" ? stepsForImages : stepsForOtherMimeTypes;
  },
  setBackgroundUrl(state, url) {
    state.backgroundUrl = url;
  },
  setByKey(state, obj) {
    state[obj.key] = obj.value;
  },
  incrementStep(state, step) {
    state.currentStep += step;
  },
  decrementStep(state, step) {
    state.currentStep -= step;
  },
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    return Object.assign(state, initialState);
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  getDutation(state, duration) {
    state.duration = duration;
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  incrementStep({ commit }, step = 1) {
    commit("incrementStep", step);
  },
  decrementStep({ commit }, step = 1) {
    commit("decrementStep", step);
  },
  changeCurrentStepsArray({ commit }, fileType) {
    commit("changeCurrentStepsArray", fileType);
  },
  setBackgroundUrl({ commit }, file) {
    commit("setBackgroundUrl", file);
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  pushError({ commit }, error) {
    commit("pushError", error);
  },
  clearErrors({ commit }) {
    commit("clearErrors");
  },
  getDutation({ commit }, duration) {
    commit("getDuration", duration);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
