import api from "../plugins/apiWrapper";

export default {
  // USERS
  async fetchMe() {
    return api.get(`/users/me`);
  },

  async fetchUsers() {
    return api.get(`/users`);
  },

  async fetchUser(id) {
    return api.get(`/users/${id}`);
  },

  async updateUser(userData, id) {
    return api.put(`/users/${id}`, userData);
  },

  async fetchNonce(publicAddress) {
    return api.get(`/getnonce/${publicAddress}`);
  },

  async verifyNonce(signature, publicAddress) {
    return api.post("/verify", { signature, publicAddress });
  },

  async signup(publicAddress) {
    return api.post("/signup", { publicAddress });
  },

  async disconnect() {
    return api.post("/disconnect");
  },

  //NFTS
  async pinFile(formData) {
    return api.post("/nfts/pin-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async fetchNftList(params) {
    return api.get(`/nfts?${params}`);
  },

  async storeNft(nftData) {
    return api.post("/nfts", nftData);
  },

  async updateNft(id, nftData) {
    return api.put(`/nfts/${id}`, nftData);
  },

  async deleteNft(id) {
    return api.delete(`/nfts/${id}`);
  },

  async storeNftFile(formData) {
    return api.post("/file-uploads/nft", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async storeCoverImageFile(formData) {
    return api.post("/file-uploads/cover-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async autogenerateCoverImage(formData) {
    return api.post("/file-uploads/autogenerate-cover", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
