<template>
  <teleport to="#app">
    <app-registration
      :closeButton="true"
      :returnToPreviousModal="props.onClose"
      class="scroll-fix"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/LOGO_GIF.gif" alt="Logo" class="logo-gif" />
          <div class="text-wrap">
            <p class="theos-header-text liquidity">LIQUIDITY MINING</p>
            <p class="theos-info-text">You need to connect a wallet</p>
            <p class="theos-info-text">in order to proceed.</p>
          </div>
          <div class="modal-buttons">
            <app-button
              text="Connect to wallet"
              buttonClass="app-modal-button-inverse"
              @click.prevent="props.onClose() && props.onClick()"
            />
          </div>
        </div>
      </template>
    </app-registration>
  </teleport>
</template>
<script>
import AppRegistration from "@/elements/AppRegistration.vue";
import AppButton from "@/elements/AppButton.vue";
export default {
  components: { AppRegistration, AppButton },
  props: {
    onClose: Function,
    onClick: Function,
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/liquidityModals.scss"
></style>
