import { isRef } from "vue";

const useUtilities = () => {
  const toggle = (target) =>
    isRef(target) ? (target.value = !target.value) : (target = !target);

  const getFileType = (file) => {
    return file.type?.split("/")[0];
  };

  const getMimeType = (file) => {
    return file.type?.split("/")[1];
  };

  const makeFileObject = async (url, fileName = "test") => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "image/gif",
    };
    return new File([data], fileName, metadata);
  };

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
  };

  return {
    toggle,
    getFileType,
    getMimeType,
    makeFileObject,
    copyToClipboard,
  };
};

export default useUtilities;
