<template>
  <app-registration>
    <template v-slot:app-modal-body>
      <registration-avatar
        :uploadedImage="profileImageUrl"
      ></registration-avatar>
      <p class="app-modal-body-text">Let others get to know you</p>
      <div class="app-modal-input-group">
        <app-input
          componentClass="section-inputs"
          v-model="user.email"
          name="email"
          placeholder="Your e-mail address"
          inputClass="app-modal-input"
        />
        <app-input
          componentClass="section-inputs"
          v-model="user.websiteUrl"
          name="websiteUrl"
          placeholder="Personal website URL"
          inputClass="app-modal-input"
        />
        <app-input
          componentClass="section-inputs"
          v-model="user.twitterUsername"
          name="twitterUsername"
          placeholder="Twitter user name"
          inputClass="app-modal-input"
        />
      </div>
      <div class="app-modal-button-controls">
        <app-button
          @click="decrementStep()"
          type="button"
          buttonClass="app-modal-button"
          text="Back"
        />
        <app-button
          type="submit"
          form="registration-process"
          buttonClass="app-modal-button-inverse"
          text="Finish"
        />
      </div>
    </template>
    <template v-slot:app-modal-footer>
      <app-dismiss text="I'll do this later" />
    </template>
  </app-registration>
</template>

<script>
import { useStore } from "vuex";
import AppRegistration from "@/elements/AppRegistration.vue";
import AppButton from "@/elements/AppButton.vue";
import AppDismiss from "@/elements/AppDismiss";
import AppInput from "@/elements/AppInput";
import useRegistrationModal from "@/composables/useRegistrationModal";
import RegistrationAvatar from "@/components/Registration/RegistrationAvatar";
export default {
  name: "RegistrationModalDetails",
  components: {
    RegistrationAvatar,
    AppRegistration,
    AppDismiss,
    AppButton,
    AppInput,
  },
  setup() {
    const store = useStore();
    const { decrementStep, profileImageUrl, user } =
      useRegistrationModal(store);
    return {
      profileImageUrl,
      decrementStep,
      user,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/registrationSteps.scss"></style>
