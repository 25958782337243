<template>
  <app-registration>
    <template v-slot:app-modal-body>
      <registration-avatar
        :uploadedImage="
          acceptedFileFormats.indexOf(appFile.type) > -1
            ? profileImageUrl
            : null
        "
      ></registration-avatar>
      <p class="app-modal-body-text">Upload a profile picture</p>
      <app-file
        maxSize="40"
        accept="image/png,image/jpeg,image/gif,image/webp"
        id="uploadedImage"
        name="uploadedImage"
        v-model="appFile"
      />
      <app-button
        type="submit"
        form="registration-process"
        buttonClass="app-modal-button"
        text="Next"
      />
    </template>
    <template v-slot:app-modal-footer>
      <app-dismiss text="I'll do this later" />
    </template>
  </app-registration>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppFile from "@/elements/AppFile";
import AppDismiss from "@/elements/AppDismiss";
import AppButton from "@/elements/AppButton.vue";
import AppRegistration from "@/elements/AppRegistration.vue";
import useRegistrationModal from "@/composables/useRegistrationModal";
import RegistrationAvatar from "@/components/Registration/RegistrationAvatar";
export default {
  name: "RegistrationModalUploadAvatar",
  components: {
    RegistrationAvatar,
    AppRegistration,
    AppDismiss,
    AppButton,
    AppFile,
  },
  setup() {
    const store = useStore();
    const { incrementStep, profileImageUrl } = useRegistrationModal(store);
    const appFile = ref({});
    const acceptedFileFormats = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/webp",
    ];
    return {
      incrementStep,
      profileImageUrl,
      appFile,
      acceptedFileFormats,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/registrationSteps.scss"></style>
