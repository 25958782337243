import api from "../../services/api";
import utils from "../utils";

const initialState = () => ({
  registeredUser: {
    id: "",
    publicAddress: "",
    nonce: "",
    updatedAt: "",
    createdAt: "",
    email: "",
    password: "",
    displayName: "",
    description: "",
    websiteUrl: "",
    twitterUsername: "",
    profileImageUrl: "",
    coverImageUrl: "",
  },
  errors: [],
});
const state = initialState();

const getters = {
  getRegisteredUser(state) {
    return state.registeredUser;
  },
  getErrors(state) {
    return state.errors;
  },
  getPublicAddress(state) {
    return state.registeredUser.publicAddress;
  },
};
const mutations = {
  setRegisteredUser(state, user) {
    state.registeredUser = user;
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async fetchNonce({ commit }, address) {
    try {
      const result = await api.fetchNonce(address);
      return result.data;
    } catch (error) {
      if (
        error.response.data.message === "User with this address doesn't exist."
      ) {
        return null;
      }
      commit("pushError", error);
    }
  },
  async verifyNonce({ commit }, { signature, publicAddress }) {
    try {
      return api.verifyNonce(signature, publicAddress);
    } catch (error) {
      commit("pushError", error);
    }
  },
  async signUp({ commit }, publicAddress) {
    try {
      const result = await api.signup(publicAddress);
      commit("setRegisteredUser", result.data.data);
      return result.data;
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchMe({ commit }) {
    try {
      const result = await api.fetchMe();
      commit("setRegisteredUser", result.data.data);
    } catch (error) {
      const isUnauthorized = await utils.handleUnauthorizedRequest(error);
      if (isUnauthorized) return "unauthorized";
      commit("pushError", error);
    }
  },
  async disconnect({ commit }) {
    try {
      await api.disconnect();
    } catch (error) {
      commit("pushError", error);
    }
  },
  async resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
