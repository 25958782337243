import axios from "axios";

const baseURL = process.env.VUE_APP_API_URL;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseURL;

// const instance = axios.create({
//   baseURL,
//   headers: {
//     "Content-type": "application/json",
//   },
//   withCredentials: true,
// });

export default axios;
