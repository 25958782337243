import { computed, onMounted } from "vue";
import apolloService from "@/services/apolloService";
import { BigNumber } from "bignumber.js";
import useAlert from "./useAlert";

const useLiquidityMiningPanel = (store, ethereumService) => {
  const session = computed(() => store.getters["users/getSession"]);

  onMounted(async () => {
    await fetchRewardData();
  });

  const fetchRewardData = async () => {
    const liquidityMiningPanel = await apolloService.getLiquidityMiningPanel(
      session
    );
    store.dispatch("liquidity/commitByKey", {
      liquidityMiningPanel,
    });
  };

  const liquidityMiningPanel = computed(
    () => store.getters["liquidity/getLiquidityMiningPanel"]
  );

  const parseMarketApy = (apy) => Number(apy).toFixed(3);

  const parsedMarketApy = computed(
    () =>
      parseMarketApy(
        liquidityMiningPanel.value?.getLiquidityMiningPanel?.liquidityMinig?.apy
      ) || 0
  );
  const allowance = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel
        ?.stakingTokenAllowance || 0
  );

  const toClaim = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.liquidityMinig
        ?.availableForClaiming || 0
  );

  const totalClaimed = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.liquidityMinig
        ?.claimed || 0
  );
  const totalEarned = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.liquidityMinig
        ?.total || 0
  );

  const total = computed(
    () => liquidityMiningPanel.value?.getLiquidityMiningPanel?.totalBalance || 0
  );
  const staked = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.stakingBalance || 0
  );
  const walletBalance = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.walletBalance || 0
  );

  const mainTokenPriceInUsd = computed(() =>
    toDecimal(
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.mainTokenPriceUSD ||
        0
    )
  );

  const modals = computed(
    () => store.getters["liquidity/getLiquidityMiningModals"]
  );

  const currentUserEthAddress = computed(
    () => store.getters["contracts/getAddress"]
  );

  const rewardsToken = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.rewardsToken?.name
  );

  const quoteToken = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.quoteToken?.symbol
  );

  const mainTokenPriceQuote = computed(
    () =>
      liquidityMiningPanel.value?.getLiquidityMiningPanel?.mainTokenPriceQuote
  );

  // Actions
  const metamaskSendTx = async (tx) => {
    try {
      store.dispatch("users/updateHasPendingTransaction", true);
      const transaction = await ethereumService.sendTransaction(tx);
      const receipt = await transaction.wait();
      store.dispatch("users/updateHasPendingTransaction", false);
      return receipt;
    } catch (error) {
      const { showAlert } = useAlert();
      if (error.code === 4001) {
        await store.dispatch("users/updateHasPendingTransaction", false);
        return false;
      }
      await store.dispatch("users/updateHasPendingTransaction", false);
      showAlert("Error", "Something went wrong!", "Error");
    }
  };

  const approveLiquidityMining = () => {
    try {
      return apolloService.approveLiquidityMining(
        session.value,
        "10000000000000000000000000000"
      );
    } catch (error) {
      return false;
    }
  };

  const claimTokens = () => {
    try {
      return apolloService.claimLiquidityMining(session.value);
    } catch (error) {
      return false;
    }
  };

  const stakeTokens = (amount) => {
    try {
      return apolloService.stakeLiquidityMining(session.value, amount);
    } catch (error) {
      return false;
    }
  };

  const unstakeTokens = (amount) => {
    try {
      return apolloService.unstakeLiquidityMining(session.value, amount);
    } catch (error) {
      return false;
    }
  };

  const handleApprove = async () => {
    const { showAlert } = useAlert();

    const tx = (await approveLiquidityMining()).data
      .createTxLiquidityMiningApprove;
    if (!tx) return showAlert("Error", "Something went wrong!", "Error");

    const receipt = await metamaskSendTx(tx);
    if (receipt) showModal("showApproved");
  };

  const handleStaking = async (amount) => {
    const { showAlert } = useAlert();
    await showModal("showStakeInitiated");

    const tx = (await stakeTokens(amount.toString())).data
      .createTxLiquidityMiningStake;
    if (!tx) return showAlert("Error", "Something went wrong!", "Error");

    const receipt = await metamaskSendTx(tx);
    await hideModal("showStakeInitiated");
    if (receipt) await showModal("showStakedSuccess");
  };

  const handleUnstaking = async (amount) => {
    const { showAlert } = useAlert();

    await showModal("showUnstakeInitiated");
    const tx = (await unstakeTokens(amount.toString())).data
      .createTxLiquidityMiningUnstake;
    if (!tx) return showAlert("Error", "Something went wrong!", "Error");

    const receipt = await metamaskSendTx(tx);
    await hideModal("showUnstakeInitiated");
    if (receipt) await showModal("showUnstakeSuccess");
  };

  const handleClaiming = async () => {
    const { showAlert } = useAlert();

    const tx = (await claimTokens()).data.createTxLiquidityMiningGetReward;
    if (!tx) return showAlert("Error", "Something went wrong!", "Error");

    const receipt = await metamaskSendTx(tx);
    if (receipt) await showModal("showClaimCongrats");
  };

  const hideModal = async (modal) =>
    await store.dispatch("liquidity/setModalsStatus", {
      [modal]: false,
    });

  const showModal = async (modal) =>
    await store.dispatch("liquidity/setModalsStatus", {
      [modal]: true,
    });

  const toDecimal = (number) => {
    BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });
    return new BigNumber(number).toFormat(3);
  };

  const toBigNumber = (number) => {
    BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });
    return new BigNumber(number);
  };

  return {
    total,
    modals,
    staked,
    toClaim,
    allowance,
    showModal,
    hideModal,
    mainTokenPriceInUsd,
    totalEarned,
    claimTokens,
    totalClaimed,
    handleStaking,
    handleApprove,
    handleClaiming,
    handleUnstaking,
    parsedMarketApy,
    fetchRewardData,
    liquidityMiningPanel,
    currentUserEthAddress,
    rewardsToken,
    mainTokenPriceQuote,
    quoteToken,
    toDecimal,
    walletBalance,
    toBigNumber,
  };
};

export default useLiquidityMiningPanel;
