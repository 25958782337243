<template>
  <div
    class="app-file-input-wrapper"
    :class="{ uploaded }"
    @dragenter.prevent=""
    @dragover.prevent=""
    @drop="dropImage"
  >
    <input
      :id="id"
      :name="name"
      v-bind="$attrs"
      type="file"
      @change="onFileChange"
      ref="inputRef"
      :accept="accept"
      hidden
    />
    <label :for="id" :class="{ uploaded: uploaded }">
      <div v-if="!uploaded">
        <img src="@/Common/Icons/Plus.png" alt="Plus" />
        <p class="app-file-input-text">
          <span class="app-file-input-title">Drag & Drop a File</span>
          <br />
          or
          <span @click.prevent="onPickFile()" class="app-file-input-upload"
            >browse media on your device</span
          >
        </p>
        <p class="app-file-input-text app-file-input-footer">
          File types supported: {{ parseAccepted() }}
          <br />
          Max size: {{ parseMaxSize() }}
        </p>
      </div>
      <div v-else>
        <img src="@/Common/Icons/UploadedSuccess.png" alt="Plus" />
        <p class="app-file-input-text">
          <span class="app-file-input-title">Image uploaded</span>
        </p>
        <p class="app-file-input-text app-file-input-footer">
          {{ fileName }}
        </p>
        <p class="app-file-input-text">
          <span @click.prevent="onPickFile()" class="app-file-input-upload"
            >Upload different file</span
          >
        </p>
      </div>
    </label>
  </div>
  <p class="input-file-error-message" v-show="errorMessage">
    {{ errorMessage }}
  </p>
</template>
<script>
import { useStore } from "vuex";
import useFileInput from "@/composables/useFileInput";
import { useField } from "vee-validate";
export default {
  name: "FileInput",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    accept: {
      type: String,
      default: "image/png,image/jpeg,image/gif,image/webp",
    },
    maxSize: {
      type: String,
      default: "",
    },
    validationFunction: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = useStore();
    const { value, errorMessage, meta } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });
    return {
      ...useFileInput(store, props, context, value),
      value,
      errorMessage,
      meta,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appFile.scss"></style>
