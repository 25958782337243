<template>
  <div v-if="registrationFlag">
    <teleport to="body">
      <base-form
        id="registration-process"
        method="post"
        :schema="currentStepsArray[currentStep - 1].schema"
        :handleSubmit="validateAndSubmit"
      >
        <component :is="currentStepsArray[currentStep - 1].component" />
      </base-form>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Stepper from "@/components/Stepper";
import BaseForm from "@/components/BaseForm";
import useRegistrationModal from "@/composables/useRegistrationModal";
import RegistrationStart from "@/components/Registration/RegistrationStart.vue";
import RegistrationDetails from "@/components/Registration/RegistrationDetails.vue";
import RegistrationUploadAvatar from "@/components/Registration/RegistrationUploadAvatar.vue";
import RegistrationNameAndDescription from "@/components/Registration/RegistrationNameAndDescription.vue";

export default {
  components: {
    Stepper,
    BaseForm,
    RegistrationStart,
    RegistrationUploadAvatar,
    RegistrationNameAndDescription,
    RegistrationDetails,
  },
  setup() {
    const store = useStore();
    const {
      registrationFlag,
      currentStep,
      incrementStep,
      finishRegistration,
      currentStepsArray,
    } = useRegistrationModal(store);

    const validateAndSubmit = async () => {
      currentStep.value === currentStepsArray.value.length
        ? await finishRegistration()
        : incrementStep();
    };
    return {
      currentStep,
      registrationFlag,
      validateAndSubmit,
      currentStepsArray,
    };
  },
};
</script>

<style lang="scss" scoped></style>
