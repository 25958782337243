<template>
  <liquidity-modal-stake-initiated
    v-if="modals?.showStakeInitiated"
    :address="currentUserEthAddress"
    :onClose="close('showStakeInitiated')"
  />
  <liquidity-modal-staked-success
    v-if="modals?.showStakedSuccess"
    :onClose="refreshPage"
  />
  <liquidity-modal-approved-congrats
    v-if="modals?.showApproved"
    :onClose="refreshPage"
  />
  <liquidity-modal-connect
    v-if="modals?.showConnect"
    :onClose="close('showConnect')"
    :onClick="loginOrSignUp"
  />
  <liquidity-modal-unstake-initiated
    v-if="modals?.showUnstakeInitiated"
    :address="currentUserEthAddress"
    :onClose="close('showUnstakeInitiated')"
  />
  <liquidity-modal-unstake-success
    v-if="modals?.showUnstakeSuccess"
    :onClose="refreshPage"
  />
  <liquidity-modal-claim-congrats
    v-if="modals?.showClaimCongrats"
    :address="currentUserEthAddress"
    :onClose="refreshPage"
  />
</template>
<script>
import LiquidityModalConnect from "./LiquidityModalConnect";
import useLiquidityMining from "@/composables/useLiquidityMining";
import LiquidityModalClaimCongrats from "./LiquidityModalClaimCongrats";
import LiquidityModalStakedSuccess from "./LiquidityModalStakedSuccess";
import LiquidityModalStakeInitiated from "./LiquidityModalStakeInitiated";
import LiquidityModalUnstakeSuccess from "./LiquidityModalUnstakeSuccess";
import LiquidityModalUnstakeInitiated from "./LiquidityModalUnstakeInitiated";
import LiquidityModalApprovedCongrats from "./LiquidityModalApprovedCongrats";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    LiquidityModalConnect,
    LiquidityModalStakedSuccess,
    LiquidityModalStakeInitiated,
    LiquidityModalApprovedCongrats,
    LiquidityModalUnstakeInitiated,
    LiquidityModalUnstakeSuccess,
    LiquidityModalClaimCongrats,
  },
  setup() {
    const store = useStore();
    const { modals, hideModal, currentUserEthAddress } = useLiquidityMining(
      store,
      ethereumService
    );
    const { loginOrSignUp } = useAuth(store, ethereumService);
    const refreshPage = () => location.reload();
    const close = (modal) => async () => await hideModal(modal);
    return {
      close,
      modals,
      refreshPage,
      currentUserEthAddress,
      loginOrSignUp,
    };
  },
};
</script>
