<template>
  <p @click="dismiss()" class="app-modal-footer-text">{{ text }}</p>
</template>

<script>
import { useStore } from "vuex";
import useRegistrationModal from "@/composables/useRegistrationModal";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const { dismiss } = useRegistrationModal(store);
    return {
      dismiss,
    };
  },
};
</script>

<style lang="scss" scoped></style>
