<template>
  <div :class="componentClass">
    <input
      v-bind="$attrs"
      v-model="checked"
      @input="$emit('update:modelValue', $event.target.value)"
      type="checkbox"
      :id="id"
      :class="checkboxClass"
    />
    <label :for="id" :class="labelClass"
      ><span :class="labelSpanClass">{{ labelText }}</span></label
    >
  </div>
</template>

<script>
export default {
  name: "AppCheckbox",
  props: {
    id: {
      type: String,
    },
    labelText: {
      type: [String],
      default: "",
    },
    modelValue: {
      type: [Boolean],
      default: false,
    },
    componentClass: {
      type: String,
      default: "",
    },
    checkboxClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    labelSpanClass: {
      type: String,
      default: "",
    },
  },
};
</script>
