const initialState = {
  liquidityMiningPanel: {},
  isConnected: false,
  errors: [],
  modals: {
    showConnect: false,
    showApproved: false,
    showStakedSuccess: false,
    showClaimCongrats: false,
    showStakeInitiated: false,
    showUnstakeSuccess: false,
    showUnstakeInitiated: false,
  },
};
const state = initialState;
const getters = {
  getByKey: (state) => (key) => state[key],
  getLiquidityMiningPanel(state) {
    return state.liquidityMiningPanel;
  },
  getLiquidityMiningModals: (state) => state.modals,
};
const mutations = {
  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
  resetState(state) {
    return Object.assign(state, initialState);
  },
  pushError(state, error) {
    state.errors.push(error);
  },
  clearErrors(state) {
    state.errors.splice(0);
  },
  setModalsStatus(state, modals) {
    state.modals = { ...state.modals, ...modals };
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  pushError({ commit }, error) {
    commit("pushError", error);
  },
  clearErrors({ commit }) {
    commit("clearErrors");
  },
  setModalsStatus({ commit }, modals) {
    commit("setModalsStatus", modals);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
