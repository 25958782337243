<template>
  <app-input
    type="number"
    v-model="amount"
    :value="amount"
    inputClass="app-checkbox-input"
    :placeholder="'0'"
    :placeholderRight="'UNI-V2 ' + rewardsToken + '/' + quoteToken"
    class="stake-amount"
  >
  </app-input>
  <p class="stake-balance">
    Balance: {{ toDecimal(walletBalance) }} UNI-V2 {{ rewardsToken }}/{{
      quoteToken
    }}
    <span class="stake-balance-max" @click="setMaxStake()">Use max.</span>
  </p>
  <app-button
    buttonClass="app-modal-button-inverse"
    :disabled="!isValidInput || !isConnected"
    :text="isValidInput ? 'Stake' : 'Enter a valid amount'"
    @click="stake()"
  />
</template>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/stake.scss"
></style>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useLiquidityMining from "@/composables/useLiquidityMining";
import { BigNumber } from "bignumber.js";

export default {
  components: {
    AppButton,
    AppInput,
  },
  setup() {
    const store = useStore();
    const amount = ref(null);
    const {
      walletBalance,
      handleStaking,
      rewardsToken,
      quoteToken,
      toBigNumber,
      toDecimal,
    } = useLiquidityMining(store, ethereumService);
    const setMaxStake = () => {
      amount.value = toDecimal(walletBalance.value);
    };

    const stake = async () => {
      const amount_temp = new BigNumber(amount.value);
      const finalAmount = amount_temp
        .multipliedBy(1_000_000_000_000_000_000)
        .toFixed();
      await handleStaking(finalAmount);
    };

    const isValidInput = computed(() => {
      return Boolean(
        toBigNumber(amount.value).isGreaterThan(toBigNumber(0)) &&
          toBigNumber(amount.value).isLessThanOrEqualTo(
            toBigNumber(walletBalance.value)
          )
      );
    });

    const isConnected = computed(() =>
      store.getters["liquidity/getByKey"]("isConnected")
    );

    return {
      amount,
      walletBalance,
      isConnected,
      setMaxStake,
      isValidInput,
      handleStaking,
      rewardsToken,
      quoteToken,
      stake,
      toDecimal,
    };
  },
};
</script>
