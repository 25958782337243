<template>
  <div></div>
</template>

<script>
import { computed, watch } from "vue";
import store from "../store";
import useAlert from "../composables/useAlert";

export default {
  name: "Errors",
  setup() {
    const { showAlert } = useAlert();
    const modules = ["nfts", "contracts", "auth", "registration"];
    let errors = {};
    modules.forEach((module) => {
      errors[module] = computed(() => store.getters[`${module}/getErrors`]);
    });

    for (let key of Object.keys(errors)) {
      watch(errors[key].value, (newValue) => {
        showAlert("Error", newValue.slice(-1)[0], "Error");
      });
    }
  },
};
</script>

<style scoped></style>
