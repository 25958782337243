<template>
  <div class="pop-up-container">
    <div class="container">
      <div class="icons-button pointer" @click="addTheosToMetamask()">
        <div class="tooltip">Add Theos to your MetaMask wallet.</div>
        <div class="icons-button-div"></div>
      </div>
      <div class="balance">
        <p class="text-balance">{{ reducedBalance }} ETH</p>
        <div class="address">
          <div v-if="isLoading" class="loader-container">
            <span class="text-address">Pending...</span>
            <app-loader
              style="padding-top: 6px"
              :show="isLoading"
              :isGreen="true"
              :is-small="true"
            />
          </div>
          <div class="address" v-else-if="!isValidNetwork">
            <p class="text-address">Invalid network</p>
            <i @click="showPopUp = true" class="address-icons pointer"></i>
          </div>
          <div class="address" v-else>
            <p class="text-address">
              {{ reduceAddress(6, 4) }}
            </p>
            <i @click="showPopUp = true" class="address-icons pointer"></i>
          </div>
        </div>
      </div>
    </div>
    <transition name="result">
      <div v-if="showPopUp" v-click-outside="hideResults" class="pop-up">
        <div class="pop-up-header">
          <h6>Wallet</h6>
          <div class="copy-icon-wrap">
            <p class="text-address">{{ reduceAddress(10, 11) }}</p>
            <div
              class="copy-icon-box pointer"
              v-click-outside="hideCopyTooltip"
              @click="copy"
            >
              <i class="copy-icon"></i>
              <input id="selectedAddress" hidden v-model="address" />
              <div v-if="copyTooltip" class="tooltip-copy">Copied!</div>
            </div>
          </div>
          <div class="active-network">
            <div class="active-network-icon"></div>
            <div class="active-network-text">
              {{ network.name }} Ethereum Network
            </div>
          </div>
        </div>
        <div class="pop-up-body">
          <h6>Balance</h6>
          <div class="balance-eth">
            <i class="icon-eth"></i>
            <p class="text-balance">~{{ reducedBalance }} ETH</p>
            <p class="text-converter">${{ coinPrice }}</p>
          </div>
        </div>
        <div class="pop-up-footer">
          <a class="disconnect" @click="disconnect">Disconnect</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppLoader from "../elements/AppLoader";
import { computed, onMounted, ref } from "vue";
import vClickOutside from "click-outside-vue3";

export default {
  components: {
    AppLoader,
  },
  props: {
    id: String,
    labelText: {
      type: String,
      default: "",
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const isLoading = computed(
      () => store.getters["users/getHasPendingTransaction"]
    );
    const addTheosToMetamask = () => {
      return ethereumService.addTheosToMetamask();
    };
    const { openRegistrationModal } = useAuth(store, ethereumService);
    let copyTooltip = ref(false);
    let showPopUp = ref(false);
    const address = computed(() => store.getters["contracts/getAddress"]);
    const balance = computed(() =>
      store.getters["contracts/getBalanceByCurrency"]("ETH")
    );
    const reducedBalance = computed(() =>
      parseFloat(balance.value || "0").toFixed(3)
    );
    const network = computed(() => store.getters["contracts/getNetwork"]);
    const coinPrice = computed(() => {
      const price = store.getters["contracts/getCoinPrice"]("ethereum", "usd");
      return price ? (price.value * balance.value).toFixed(0) : 0;
    });
    onMounted(async () => {
      await store.dispatch("contracts/fetchAddress");
      await store.dispatch("contracts/fetchBalance", address.value);
      await store.dispatch("contracts/fetchCoinPrice", {});
    });
    const disconnect = async () => {
      await store.dispatch("contracts/resetState");
      await store.dispatch("users/resetState");
      await ethereumService.disconnectProvider();
      await router.push("/");
    };
    const copy = () => {
      copyTooltip.value = true;
      navigator.clipboard.writeText(address.value);
    };
    const reduceAddress = (lengthLeft, lengthRight) => {
      if (address.value) {
        const addressLeftPart = address.value.substring(0, lengthLeft);
        const addressRightPart = address.value.substring(42 - lengthRight, 42);
        return `${addressLeftPart}...${addressRightPart}`;
      }
      return "Not connected";
    };
    const isValidNetwork = computed(() =>
      ["homestead", "ropsten", "rinkeby"].includes(network.value.name)
    );
    const hideCopyTooltip = () => {
      copyTooltip.value = false;
    };
    const hideResults = () => {
      showPopUp.value = false;
    };
    const userIsRegistered = () =>
      store.getters["userProfile/getUser"].displayName;
    return {
      copy,
      address,
      balance,
      network,
      coinPrice,
      isLoading,
      showPopUp,
      disconnect,
      copyTooltip,
      hideResults,
      reduceAddress,
      isValidNetwork,
      reducedBalance,
      hideCopyTooltip,
      userIsRegistered,
      addTheosToMetamask,
      openRegistrationModal,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/UserBalanceAndAddress.scss"
></style>
