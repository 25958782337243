import useAuth from "../composables/useAuth";
import store from "../store";
import { ethereumService } from "@/main";
import { useRouter } from "vue-router";

export default {
  async handleUnauthorizedRequest(error) {
    if (error.response.status === 401) {
      const { loginOrSignUp } = useAuth(store, ethereumService, useRouter);
      await store.dispatch("auth/disconnect");
      await loginOrSignUp(true);
      return true;
    }
    return false;
  },
};
