import {
  provideApolloClient,
  useMutation,
  useQuery,
  useResult,
} from "@vue/apollo-composable";

import { apolloClient } from "../plugins/vue-apollo";
import { gqlQueries } from "../gqlQueries";

provideApolloClient(apolloClient);

const CONTRACT_ADDRESS = process.env.VUE_APP_LM_ADDRESS || "";

export default {
  fixSign(sign) {
    const m = sign.replace("0x", "").split("");
    let V = parseInt(`${m[128]}${m[129]}`, 16);
    if (V === 0 || V === 1) {
      V = (V + 27) % 256;
    }
    const Vs = V.toString(16);
    if (Vs.length === 1) {
      m[128] = "0";
      m[129] = Vs[0];
    } else {
      m[128] = Vs[0];
      m[129] = Vs[1];
    }
    return "0x" + m.join("");
  },

  async getAuthenticationMessage(address) {
    const { mutate: getAuthMessage } = useMutation(
      gqlQueries.GetAuthMessageDocument
    );
    const res = await getAuthMessage({ address });
    return res.data?.getAuthMessage;
  },

  async getAuth(signature, authMessage) {
    const { mutate: getAuth } = useMutation(gqlQueries.AuthDocument);
    const res = await getAuth({ input: { sign: signature, authMessage } });
    return res.data.auth.session;
  },

  async getLiquidityMiningPanel(session) {
    const { result } = await useQuery(
      gqlQueries.GetLiquidityMiningPanelDocument,
      {
        session,
        address: CONTRACT_ADDRESS,
      }
    );
    return await useResult(result, null, (data) => data);
  },

  async approveLiquidityMining(session, amount) {
    const { mutate: ApproveLiquidityMining } = useMutation(
      gqlQueries.ApproveLiquidityMiningDocument
    );
    return await ApproveLiquidityMining({
      session,
      input: { amount, address: CONTRACT_ADDRESS },
    });
  },

  async claimLiquidityMining(session) {
    const { mutate: ClaimLiquidityMining } = useMutation(
      gqlQueries.ClaimLiquidityMiningDocument
    );
    return await ClaimLiquidityMining({
      session,
      address: CONTRACT_ADDRESS,
    });
  },

  async stakeLiquidityMining(session, amount) {
    const { mutate: StakeLiquidityMining } = useMutation(
      gqlQueries.StakeLiquidityMiningDocument
    );
    return await StakeLiquidityMining({
      session,
      input: { amount, address: CONTRACT_ADDRESS },
    });
  },

  async unstakeLiquidityMining(session, amount) {
    const { mutate: UnstakeLiquidityMining } = useMutation(
      gqlQueries.UnstakeLiquidityMiningDocument
    );
    return await UnstakeLiquidityMining({
      session,
      input: { amount, address: CONTRACT_ADDRESS },
    });
  },

  getTransaction(session, txHash) {
    const { result } = useQuery(gqlQueries.GetTransactionDocument, {
      session,
      txHash,
    });
    return useResult(result, null, (data) => data);
  },
};
