<template>
  <div class="stake-wrap">
    <app-checkbox
      v-model="isUnstake"
      componentClass="switch-button"
      checkboxClass="switch-button-checkbox"
      labelClass="switch-button-label"
      labelSpanClass="switch-button-label-span"
      labelText="Stake"
    />

    <p class="stake-text">Amount</p>

    <approve-form v-if="allowance === 0" />
    <stake-form v-if="allowance !== 0 && !isUnstake" />
    <unstake-form v-if="allowance !== 0 && isUnstake" />
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import StakeForm from "./Forms/StakeForm";
import AppCheckbox from "@/elements/AppCheckbox";
import UnstakeForm from "./Forms/UnstakeForm";
import ApproveForm from "./Forms/ApproveForm";
import useLiquidityMining from "@/composables/useLiquidityMining";

export default {
  components: {
    AppCheckbox,
    StakeForm,
    UnstakeForm,
    ApproveForm,
  },
  setup() {
    const store = useStore();
    const { allowance, handleStaking, liquidityMiningPanel } =
      useLiquidityMining(store, ethereumService);

    const isUnstake = ref(false);

    return {
      allowance,
      isUnstake,
      handleStaking,
      liquidityMiningPanel,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/stake.scss"
></style>
