import useRegistrationModal from "@/composables/useRegistrationModal";
import { ref } from "vue";

const useFileInput = (myStore, props, context, value) => {
  const { uploadImage } = useRegistrationModal(myStore);
  const uploaded = ref(false);

  const parseAccepted = () =>
    props.accept
      .split(",")
      .map((type) => type.split("/")[1].toUpperCase())
      .join(", ");
  const parseMaxSize = () => `${props.maxSize} MB`;

  const url = ref(null);
  const inputRef = ref("dropFile");
  const fileName = ref("");

  const onFileChange = () => {
    const file = inputRef.value.files;
    uploaded.value = true;
    fileName.value = file[0].name;
    value.value = file[0];
    url.value = URL.createObjectURL(file[0]);
    context.emit("update:modelValue", file[0]);
    uploadImage(url.value);
  };

  const dropImage = (e) => {
    e.preventDefault();
    inputRef.value.files = e.dataTransfer.files;
    onFileChange();
  };
  const onPickFile = () => {
    inputRef.value.click();
  };
  return {
    url,
    inputRef,
    onFileChange,
    dropImage,
    onPickFile,
    parseAccepted,
    parseMaxSize,
    uploaded,
    fileName,
  };
};

export default useFileInput;
