<template>
  <app-registration>
    <template v-slot:app-modal-body>
      <registration-avatar
        :uploadedImage="profileImageUrl"
      ></registration-avatar>
      <p class="app-modal-body-text">Set your display name and description</p>
      <div class="app-modal-input-group">
        <app-input
          componentClass="section-inputs"
          v-model="user.displayName"
          type="text"
          name="displayName"
          placeholder="Enter your display name"
          inputClass="app-modal-input"
        />
        <app-textarea
          v-model="user.description"
          type="text"
          name="description"
          placeholder="Tell us about yourself in a few words"
          componentClass="section-inputs"
          textAreaClass="app-modal-textarea"
          cols="1"
          rows="3"
        />
      </div>
      <div class="app-modal-button-controls">
        <app-button
          @click="decrementStep()"
          type="button"
          buttonClass="app-modal-button"
          text="Back"
        />
        <app-button
          type="submit"
          form="registration-process"
          buttonClass="app-modal-button-inverse"
          text="Next"
        />
      </div>
    </template>
    <template v-slot:app-modal-footer>
      <app-dismiss text="I'll do this later" />
    </template>
  </app-registration>
</template>

<script>
import { useStore } from "vuex";
import AppRegistration from "@/elements/AppRegistration.vue";
import AppButton from "@/elements/AppButton.vue";
import AppInput from "@/elements/AppInput";
import AppDismiss from "@/elements/AppDismiss";
import AppTextarea from "@/elements/AppTextarea";
import useRegistrationModal from "@/composables/useRegistrationModal";
import RegistrationAvatar from "@/components/Registration/RegistrationAvatar";

export default {
  name: "RegistrationModalNameAndBio",
  components: {
    RegistrationAvatar,
    AppRegistration,
    AppDismiss,
    AppButton,
    AppInput,
    AppTextarea,
  },
  setup() {
    const store = useStore();
    const { decrementStep, profileImageUrl, user } =
      useRegistrationModal(store);

    return {
      profileImageUrl,
      decrementStep,
      user,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/registrationSteps.scss"></style>
