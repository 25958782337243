import CoinGecko from "coingecko-api";

const CoinGeckoClient = new CoinGecko();

export default {
  async fetchPriceInFiat(coins = "ethereum", currencies = "usd") {
    return CoinGeckoClient.simple.price({
      ids: coins,
      vs_currencies: currencies,
    });
  },
};
