import { createStore } from "vuex";
import contracts from "./modules/contracts";
import tagsValues from "./modules/tagsValues";
import minting from "./modules/minting";
import registration from "./modules/registration";
import auth from "./modules/auth";
import nfts from "./modules/nfts";
import users from "./modules/users";
import userProfile from "./modules/userProfile";
import liquidity from "./modules/liquidity";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    contracts: state.contracts,
    users: state.users,
  }),
  storage: window.localStorage,
});

export default createStore({
  modules: {
    contracts,
    tagsValues,
    minting,
    registration,
    auth,
    nfts,
    users,
    userProfile,
    liquidity,
  },
  plugins: [vuexLocal.plugin],
});
