import * as Yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/gif",
  "image/webp",
  "video/mp4",
  "video/webm",
  "audio/mp3",
  "audio/webm",
  "audio/mpeg",
];
const MAX_FILE_SIZE = 41943040;

export const mintNftCoverImageScheme = Yup.object().shape({
  coverImageFile: Yup.mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "The File Size should be less then 40MB",
      (value) => value.size <= MAX_FILE_SIZE
    )
    .test(
      "FILE_FORMAT",
      "This field is required or uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});
