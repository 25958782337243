<template>
  <app-registration>
    <template v-slot:app-modal-header>
      <img src="@/Common/Icons/TheosLogo.svg" alt="TheosLogo" />
      <h3 class="app-modal-welcome-title">Welcome</h3>
    </template>
    <template v-slot:app-modal-body>
      <p class="app-modal-body-text">
        Since this is your first time on Theos - we'll help you setting up your
        profile
      </p>
      <app-button
        @click="incrementStep()"
        buttonClass="app-modal-button"
        text="Let's start"
      />
    </template>
    <template v-slot:app-modal-footer>
      <app-dismiss text="I'll do this later" />
    </template>
  </app-registration>
</template>

<script>
import { useStore } from "vuex";
import AppButton from "@/elements/AppButton.vue";
import AppDismiss from "@/elements/AppDismiss.vue";
import AppRegistration from "@/elements/AppRegistration.vue";
import useRegistrationModal from "@/composables/useRegistrationModal";
export default {
  name: "RegistrationModalStart",
  components: {
    AppRegistration,
    AppButton,
    AppDismiss,
  },
  setup() {
    const store = useStore();
    const { incrementStep } = useRegistrationModal(store);
    return {
      incrementStep,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/registrationSteps.scss"></style>
