<template>
  <div class="not-supported" v-if="isMobileDevice">
    <mobile-not-supported></mobile-not-supported>
  </div>
  <div class="app-wrapper" v-else>
    <div>
      <app-menu-bar />
    </div>
    <router-view />
    <errors />
    <app-alert></app-alert>
    <registration-modal></registration-modal>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Errors from "./components/Errors";
import { ethereumService } from "./main";
import useAuth from "./composables/useAuth";
import AppAlert from "@/elements/AppAlert.vue";
import AppMenuBar from "./components/AppMenuBar.vue";
import useLiquidityMining from "./composables/useLiquidityMining";
import RegistrationModal from "@/components/Registration/RegistrationModal.vue";
import MobileNotSupported from "@/components/MobileNotSupported";

export default {
  components: {
    Errors,
    AppAlert,
    AppMenuBar,
    RegistrationModal,
    MobileNotSupported,
  },
  setup() {
    const isMobileDevice = ref(false);
    const handleWindowResize = () =>
      (isMobileDevice.value = window.innerWidth < 991);
    window.addEventListener("resize", handleWindowResize);

    // const pendingTx = computed(() => {
    //   return store.getters["users/getPendingTransactions"]
    // });

    const store = useStore();
    const { showModal } = useLiquidityMining(store, ethereumService);
    onMounted(async () => {
      handleWindowResize();
      const { handleAccountChange, handleChainChange } = useAuth(
        store,
        ethereumService
      );
      await store.dispatch("contracts/fetchNetwork");
      await ethereumService.onAccountChanged(handleAccountChange);
      await ethereumService.onChainChanged(handleChainChange);
      const result = await ethereumService.isProviderConnected();
      await store.dispatch("liquidity/commitByKey", { isConnected: result });
      if (!result) {
        await showModal("showConnect");
      }
      await store.dispatch("users/updateHasPendingTransaction", false);
    });

    // console.log(pendingTx.value);
    // pendingTx.value.forEach(tx => {
    //     tx.wait()
    // });
    // watch(pendingTx, async (newPendingTx) => {
    //   console.log(newPendingTx);
    //   newPendingTx.forEach(tx => {
    //     tx.wait()
    //   });
    //   // if (newPendingTx) return
    //   // await store.dispatch("users/updateHasPendingTransaction", true);
    //   // pendingTx.value.forEach(async tx => tx.wait());
    //   // await store.dispatch("users/updateHasPendingTransaction", false);
    // })
    return {
      isMobileDevice,
    };
  },
};
</script>
