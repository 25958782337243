<template>
  <app-input
    type="number"
    v-model="amount"
    :value="amount"
    inputClass="app-checkbox-input"
    :placeholder="'0'"
    :placeholderRight="'UNI-V2 ' + rewardsToken + '/' + quoteToken"
    class="stake-amount"
    disabled="true"
  >
  </app-input>
  <p class="stake-balance">
    Balance: {{ balance }} UNI-V2 {{ rewardsToken }}/{{ quoteToken }}
  </p>
  <app-button
    v-if="isConnected"
    :disabled="hasPendingTx"
    buttonClass="approve-token-button"
    :text="'Approve UNI-V2 ' + rewardsToken + '/' + quoteToken + ' LP tokens'"
    @click="handleApprove(amount)"
  />
  <app-button
    v-else
    :disabled="false"
    buttonClass="approve-token-button"
    :text="'Connect your wallet to stake'"
    @click="loginOrSignUp()"
  />
</template>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/stake.scss"
></style>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import useLiquidityMining from "@/composables/useLiquidityMining";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    AppButton,
    AppInput,
  },
  setup() {
    const store = useStore();
    const amount = ref(null);
    const { handleApprove, rewardsToken, quoteToken } = useLiquidityMining(
      store,
      ethereumService
    );

    const { loginOrSignUp } = useAuth(store, ethereumService);

    const hasPendingTx = computed(
      () => store.getters["users/getHasPendingTransaction"]
    );
    const isConnected = computed(() =>
      store.getters["liquidity/getByKey"]("isConnected")
    );

    return {
      amount,
      isConnected,
      hasPendingTx,
      handleApprove,
      rewardsToken,
      quoteToken,
      loginOrSignUp,
    };
  },
};
</script>
