import * as Yup from "yup";

export const mintNftDetailsScheme = Yup.object().shape({
  Royalties: Yup.number()
    .required()
    .typeError("Royalties is required field")
    .min(0, "Min value  has to be larger then 0")
    .max(100, "Max value has to be less than 100")
    .test(
      "MAX_DECIMALS",
      "Royalties field must have 2 decimals or less",
      (number) => Number.isInteger(number * 10 ** 2)
    ),
});
