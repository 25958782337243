<template>
  <div class="liquidity-owerview-wrap">
    <p class="liquidity-header-text">Uniswap liquidity mining overview</p>
    <div class="info-button-wrap">
      <div class="info-liquidity-wrap">
        <div class="info-data-liquidity-overview">
          <div class="info-text">
            <p>Curt. market APY</p>
            <div class="info-text-hover">
              <img
                class="info-icon"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="message-hover curt-market">
                This figure represents the expected annual <br />
                interest rate you would get from staking <br />
                your Uniswap {{ rewardsToken }}/{{ quoteToken }} liquidity
                tokens, <br />
                given the current market conditions.
              </div>
            </div>
          </div>
          <p class="market-liquidity-overview">
            {{ Number(parsedMarketApy * 100).toFixed(2) }}%
          </p>
        </div>
        <div class="info-data-liquidity-overview">
          <div class="info-text">
            <p>Total earned</p>
            <div class="info-text-hover">
              <img
                class="info-icon"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="message-hover">
                The total amount of {{ rewardsToken }} you have <br />
                earned with the liquidity mining program.
              </div>
            </div>
          </div>
          <p class="total-earned-liquidity-overview">
            {{ Number(totalEarned).toFixed(3) }}
            <span class="theos-text">{{ rewardsToken }}</span>
          </p>
        </div>
        <div class="info-data-liquidity-overview">
          <div class="info-text">
            <p>Total claimed</p>
            <div class="info-text-hover">
              <img
                class="info-icon"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="message-hover claimed">
                {{ rewardsToken }} amount already claimed.
              </div>
            </div>
          </div>
          <p class="total-claimed-liquidity-overview">
            {{ Number(totalClaimed).toFixed(3) }}
            <span class="theos-text">{{ rewardsToken }}</span>
          </p>
        </div>
        <div class="info-data-liquidity-overview">
          <div class="info-text">
            <p>Avail. for claiming</p>
            <div class="info-text-hover">
              <img
                class="info-icon"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="message-hover">
                {{ rewardsToken }} amount currently available for <br />
                claiming.
              </div>
            </div>
          </div>
          <p class="available-liquidity-overview">
            {{ Number(toClaim).toFixed(3) }}
            <span class="theos-text">{{ rewardsToken }}</span>
          </p>
        </div>
      </div>
      <div>
        <app-button
          text="Claim"
          class="claim-button"
          :disabled="!isConnected || allowance === 0 || toClaim === 0"
          @click="handleClaiming()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AppButton from "@/elements/AppButton.vue";
import { useStore } from "vuex";
import useLiquidityMining from "@/composables/useLiquidityMining";
import { ethereumService } from "@/main";
import { computed } from "vue";
export default {
  components: { AppButton },
  setup() {
    const store = useStore();
    const isConnected = computed(() =>
      store.getters["liquidity/getByKey"]("isConnected")
    );
    const {
      parsedMarketApy,
      toClaim,
      totalEarned,
      totalClaimed,
      allowance,
      claimTokens,
      handleClaiming,
      rewardsToken,
      quoteToken,
    } = useLiquidityMining(store, ethereumService);
    return {
      parsedMarketApy,
      toClaim,
      totalClaimed,
      totalEarned,
      allowance,
      claimTokens,
      handleClaiming,
      isConnected,
      rewardsToken,
      quoteToken,
    };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/liquidityMiningOverview.scss"
></style>
