<template>
  <div :class="componentClass">
    <div class="royalties-icon-wrap">
      <label :for="id" :class="labelClass">{{ labelText }}</label>
      <div v-if="name == 'Royalties'" class="royalties-info">
        <img
          class="royalties-icon royalties-img"
          src="@/Common/Icons/InfoRoyaltiesIcon.png"
        />
        <div class="royalties-hover">
          Collect a fee when a user re-sells an item<br />
          you originally created.
        </div>
      </div>
    </div>
    <div :class="inputIcons">
      <i
        v-if="inputIconLeftClass"
        :class="inputIconLeftClass"
        :style="inputIconLeftStyle"
      ></i>
      <i
        v-if="inputIconRightClass"
        :class="inputIconRightClass"
        :style="inputIconRightStyle"
      ></i>
      <div class="app-input-div">
        <label v-if="placeholderRight" class="app-input-label" :for="id">{{
          placeholderRight
        }}</label>
        <input
          v-bind="$attrs"
          v-model="inputValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :id="id"
          :name="name"
          :disabled="disabled"
          :placeholder="placeholder"
          :class="[inputClass, errorMessage ? 'error-message-border' : '']"
          :style="inputStyle"
        />
      </div>
      <div class="percents-box" v-if="suggestions">
        <p>Suggestions:</p>

        <div v-for="(percent, index) in percents" :key="index" class="percents">
          <div>{{ percent.text }},</div>
        </div>
      </div>
    </div>
    <p
      class="validation-error-message"
      :class="messageClass"
      v-show="errorMessage || !meta.valid"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
export default {
  name: "AppInput",
  props: {
    id: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    placeholderRight: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    componentClass: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: Object,
      default: () => {},
    },
    inputIconLeftClass: {
      type: String,
      default: "",
    },
    inputIconLeftStyle: {
      type: Object,
      default: () => {},
    },
    inputIconRightClass: {
      type: Object,
      default: () => {},
    },
    inputIconRightStyle: {
      type: Object,
      default: () => {},
    },
    inputIcons: {
      type: String,
      default: "",
    },
    messageClass: {
      type: String,
      default: "",
    },
    suggestions: {
      type: Boolean,
      default: false,
    },
    percents: {
      type: Array,
      default: () => [],
    },
    validationFunction: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      meta,
    } = useField(props.name, props.validationFunction() || undefined, {
      initialValue: props.modelValue,
    });
    return {
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appInput.scss"></style>
