<template>
  <div class="theos-info-wrap">
    <p class="theos-info-value">
      1 {{ rewardsToken }} =
      <span class="">{{ mainTokenPriceInUsd }} USD</span>
    </p>
    <div class="theos-info-info">
      <img class="info-icon" src="@/Common/Icons/AddLiquidity.png" />
      <a
        href="https://v2.info.uniswap.org/pair/0x5c392b2cf73c052315cba76b065effb8f06f1ffa"
        class="uniswap-pool-text"
        target="_blank"
        >View pool in Uniswap</a
      >
    </div>
  </div>
</template>
<script>
import useLiquidityMining from "@/composables/useLiquidityMining";
import { ethereumService } from "../../main";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const { mainTokenPriceInUsd, quoteToken, rewardsToken } =
      useLiquidityMining(store, ethereumService);
    return { mainTokenPriceInUsd, quoteToken, rewardsToken };
  },
};
</script>
<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/theosInfo.scss"
></style>
