<template>
  <div class="menu-bar">
    <div class="logo-and-menu">
      <a class="pointer" href="https://app.theos.fi/">
        <img
          src="@/Common/Icons/THEOS_LOGO_METAL.svg"
          alt="Logo"
          class="theos-logo"
        />
      </a>
    </div>
    <div class="button-container">
      <app-button
        v-if="!address"
        buttonClass="button-primary-default pointer"
        text="Connect to a wallet"
        @click="loginOrSignUp()"
      />
      <user-balance-and-address v-else />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { ethereumService } from "@/main";
import useAuth from "@/composables/useAuth";
import AppButton from "@/elements/AppButton.vue";
import UserBalanceAndAddress from "../components/UserBalanceAndAddress.vue";

export default {
  components: { AppButton, UserBalanceAndAddress },
  setup() {
    const store = useStore();
    const { loginOrSignUp, openRegistrationModal } = useAuth(
      store,
      ethereumService
    );
    const address = computed(() => store.getters["contracts/getAddress"]);

    return {
      loginOrSignUp,
      address,
      openRegistrationModal,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/AppMenuBarStyles.scss"></style>
