<template>
  <teleport to="body">
    <app-registration
      :closeButton="true"
      :returnToPreviousModal="props.onClose"
      class="scroll-fix"
    >
      <template v-slot:app-modal-body>
        <div class="app-modal-body-wrapper">
          <img src="@/Common/Icons/LOGO_GIF.gif" alt="Logo" class="logo-gif" />
          <div class="text-wrap">
            <p class="theos-header-text">TRANSACTION</p>
            <p class="theos-header-text liquidity">INITIATED</p>
            <p class="theos-info-text">
              Your unstaking transaction is in progress.
            </p>
            <p class="theos-info-text">
              You can check the status in Etherscan.
            </p>
          </div>
          <div class="modal-buttons">
            <app-button
              text="View on Etherscan"
              buttonClass="app-modal-button"
              class="etherscan-button"
              @click.prevent="viewInEtherscan()"
            />
            <p class="close-modal pointer" @click.prevent="props.onClose()">
              Close
            </p>
          </div>
        </div>
      </template>
    </app-registration>
  </teleport>
</template>
<script>
import AppRegistration from "@/elements/AppRegistration.vue";
import AppButton from "@/elements/AppButton.vue";
export default {
  components: { AppRegistration, AppButton },
  props: {
    onClose: Function,
    address: String,
  },
  setup(props) {
    const viewInEtherscan = () => {
      window.open(
        `${process.env.VUE_APP_ETHERSCAN_URL}/address/${props.address}`
      );
    };
    return {
      props,
      viewInEtherscan,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
  src="@/Common/Styles/LiquidityMining/liquidityModals.scss"
></style>
